import {
    REACT_APP_SECRET_KEY,
    REACT_APP_ENVIRONMENT, REACT_APP_PRODUCT_VIEW,
    REACT_APP_CRM_BASE_URL, REACT_APP_TYPE_SENSE_KEY,
    REACT_NO_IMAGE, REACT_APP_URL_OFFER_PROJECT_BASE_URL,
    REACT_APP_ORG_API_URL, REACT_APP_EXOTEL_API_URI_PATH,
    REACT_APP_APX_URL, REACT_APP_CENTRAL_INVENTORY_API_PATH,
    REACT_APP_OFFER_API_TOKEN, REACT_APP_PLAY_STORE_URI_PATH,
    REACT_APP_CRM_BASE_URL_WITH_OUT_API, REACT_APP_OFFER_API,
    REACT_APP_TYPE_SENSE, REACT_APP_PIM_URL, REACT_APP_ECOM_BASE_PATH,
} from "./stage";

export const noImage = REACT_NO_IMAGE; //NO IMAGE
export const pimBaseUrl = REACT_APP_PIM_URL; //pimUrl
export const productOffer = REACT_APP_OFFER_API; //offer api
export const apxBaseUrlPath = REACT_APP_APX_URL;//apx base url
export const orgBaseUrl = REACT_APP_ORG_API_URL;//org base url
export const eComPath = REACT_APP_ECOM_BASE_PATH; // ecomPath
export const environment = REACT_APP_ENVIRONMENT; //environment
export const secretKeyData = REACT_APP_SECRET_KEY;//crm project base url
export const crmApiBaseUrl = REACT_APP_CRM_BASE_URL;//crm project base url
export const typesenceBasePath = REACT_APP_TYPE_SENSE; //type sense apiPath
export const productDetailsGet = REACT_APP_PRODUCT_VIEW;
export const typesenceTokenKey = REACT_APP_TYPE_SENSE_KEY; //type sense token
export const exotelApiUrl = REACT_APP_EXOTEL_API_URI_PATH; //exotel
export const productOfferToken = REACT_APP_OFFER_API_TOKEN; //offer api
export const playStoreUriPath = REACT_APP_PLAY_STORE_URI_PATH;//play store path
export const centralInventoryApi = REACT_APP_CENTRAL_INVENTORY_API_PATH;//centralInventoryApi
export const offerProjectBasePath = REACT_APP_URL_OFFER_PROJECT_BASE_URL;//OFFER project base path
export const crmApiBaseUrlWithOutApi = REACT_APP_CRM_BASE_URL_WITH_OUT_API;//OFFER project base path

/**
 * set defaultValue
 */
export const staticData = {
    pinCode: 6,
    MobileNo: 10,
    imeilength: 30,
    thirtyMbHandle: 31457280,
};
/**
  * @param  {object} params;
  * Api request params convert to queryParams
*/
export const encodeGetParams = (params = {}) => {
    return Object.entries(params).map((key) => key.map((k) => {
        const enCodeData = (typeof k === 'object') ? JSON.stringify(k) : k;
        return encodeURIComponent(enCodeData);
    }).join("=")).join("&");
};

/**
 * login related api path
 */
export const loginApi = {
    loginPage: "v1/auth/login", //login
    changePassword: "v1/auth/change-password", //create password
};

export const commonEnquires = {
    myFollowup: "v1/enquires/my-followups", //my followups
};

export const salesProduct = {
    product: "multi_search", //typesense
    pimProxyApi: "pim/productpricing/", //proxy
    pimProxyProdApi: "pim/product/", //proxy
    salesEndPoint: "v1/enquires/sales", //sales update
    ticketId: "v1/enquires/all/ticket", //ticket id based get information
    productDeatails: "api/v1/product/", //get full details
    pimOfflinePrice: "api/v1/productpricing/", //offline price
    multipleProduct: "v1/enquires/sales/many", //sales multiple prodcut added same api
    deliveryTypeGet: "api/v1/deliverytype/pincode", //get delivery type based on pincode
    salesStatus: "v1/common/static-data?type=salesStatus", //sales update status list
    salesSource: "v1/common/static-data?type=salesSource", //sales update source list
    productAvailabilityCheck: "api/v1/deliverytype/pincode/address", //pinCode based avilable check
    getShowroomPrice: "api/apxapi/GetPriceFromPriceTemplate?CompanyCode=pmpl&PriceEffetiveFrom=",
};

/**
 * offer
 */
export const offerProjectEndPath = {
    productOffer: "/ruleEngine/offers",
    offerDetailsGet: "offers_all_datas/",
};

/**
 * branch assign
 */
export const getAssignBranchDetailsApiPath = {
    getAssignBranchDetailsBasedStaff: "v1/users/list", //get staff details
    getAssignBranchDetails: "v1/showroom/branches/list", //get showroom list
    syncAssignBranchDetails: "v1/showroom/branches/sync", //get showroom list
};

export const userBasicDetail = {
    updateBasicInfo: "v1/customers", //update customers
    getUserDetails: "v1/customers/", //get user details using phone number
    areaGet: "api/oc3/pincodeapilist.php", //get state details
};

export const phoneCall = {
    sidId: "poorvika1",
    callerId: "07949106000",
    subDomain: "@api.exotel.com",
    excotelWebHook: "webhook/app/enq-calls",
    apiKey: "5014cb1bf71241f6977afd9085188ccbff0f9e102f14efe8",
    apiToken: "5a25c701acfd2432e5c80e403a99c76b194847052ca3df51",
};

export const dashBoardApi = { //dashBoard
    getEmpIdBasedEnq: "v1/dashboard/by-user", //table
};

export const cusFeedBack = {
    customerFeedBack: "v1/enquires/customer-feedback", //table
};
